<template>
  <div>
    <!-- Totalizadores -->
    <b-row
      :class="[
        'd-flex align-items-center justify-content-center mb-1',
        chartLength !== 0 ? 'mr-2' : '',
      ]"
    >
      <b-col :cols="colsPartition.table">
        <CommissionManagementTotalizer :totalizer="totalizer" />
      </b-col>
      <b-col
        v-if="chartLength !== 0"
        :cols="colsPartition.chart"
        class="d-flex align-items-center justify-content-center"
      >
        <commission-management-chart :spinnerActive="loading.search" />
      </b-col>
    </b-row>

    <!-- Filters -->
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <commission-management-filters
        :spinnerActive="loading.search"
        :search="search"
        ref="filters"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontradas empresas para esta busca"
        :busy="loading.search"
      >
        <!-- Column: PN -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.consultant_name }}</span>
          </div>
        </template>
        <!-- Column: Cliente -->
        <template #cell(customer_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.customer_name }}</span>
          </div>
        </template>
        <!-- Column: Solução Finanaceira -->
        <template #cell(financial_solution_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.financial_solution_name
            }}</span>
          </div>
        </template>
        <!-- Column: Conciliador do pagamento -->
        <template #cell(payment_conciliator)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.payment_conciliator | paymentConciliator
            }}</span>
          </div>
        </template>
        <!-- Column: Parcela -->
        <template #cell(commission_installments)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.commission_installments
            }}</span>
          </div>
        </template>
        <!-- Column: Valor recebido -->
        <template #cell(commission_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.commission_amount | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Data do contrato -->
        <template #cell(contract_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.contract_date | onlyDate
            }}</span>
          </div>
        </template>
        <!-- Column: Data do pagamento -->
        <template #cell(reconciled_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.reconciled_date | onlyDate
            }}</span>
          </div>
        </template>
        <!-- Column: Adicionado por -->
        <template #cell(added_by_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.added_by_name }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { getRangeDates } from "@/helpers/date_picker";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import CommissionManagementFilters from "../components/CommissionManagementFilters";
import CommissionManagementTotalizer from "../components/CommissionManagementTotalizer";
import CommissionManagementChart from "../components/CommissionManagementChart";
import { useWindowSize } from "@vueuse/core";

export default {
  components: {
    vSelect,
    DynamicSelect,
    flatPickr,
    CommissionManagementFilters,
    CommissionManagementTotalizer,
    CommissionManagementChart,
  },
  setup() {
    const { skin } = useAppConfig();
    const { width, height } = useWindowSize();

    return { toast: useToast(), skin, width, height };
  },
  data() {
    return {
      loading: {
        search: false,
      },
      tableColumns: [
        { key: "consultant_name", label: "PN" },
        { key: "customer_name", label: "Cliente" },
        { key: "financial_solution_name", label: "Solução Financeira" },
        { key: "payment_conciliator", label: "Conciliador do pagamento" },
        { key: "commission_installments", label: "Parcela" },
        { key: "commission_amount", label: "Valor recebido" },
        { key: "contract_date", label: "Data do contrato" },
        { key: "reconciled_date", label: "Data do pagamento" },
        { key: "added_by_name", label: "Adicionado por" },
      ],
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      currentPage: 1,
      itemsPerPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.COMMISSION_MANAGEMENT_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      totalizer: types.GET_COMMISSION_MANAGEMENT_TOTALIZER,
      chartItems: types.CHART_COMMISSION_MANAGEMENT,
    }),
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
    chartLength() {
      return this.chartItems?.length;
    },
    colsPartition: function () {
      let cols;
      if (this.width >= 1800 && this.chartLength !== 0) {
        cols = {
          table: 6,
          chart: 6,
        };
      } else if (this.width <= 768 || this.chartLength === 0) {
        cols = {
          table: 12,
          chart: 12,
        };
      } else if (this.chartLength !== 0) {
        cols = {
          table: 4,
          chart: 8,
        };
      }
      return cols;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      searchCommissionManagement: types.SEARCH_COMMISSION_MANAGEMENT,
      getTotalizer: types.COMMISSION_MANAGEMENT_TOTALIZER,
      searchChartCommissionManagement: types.SEARCH_CHART_COMMISSION_MANAGEMENT,
    }),
    search(currentPage) {
      this.loading.search = true;
      if (!this.$refs.filters) return;
      const {
        customer,
        partnerCompany,
        product,
        consultant,
        structureOption,
        franchise,
        creditType,
        rangeDate,
        paymentConciliator,
        financialSolution,
      } = this.$refs.filters;
      const range_date = getRangeDates(rangeDate);
      console.log(this.colsPartition);
      this.searchCommissionManagement({
        customer,
        partner_company: partnerCompany,
        product,
        date_start: range_date.start,
        date_end: range_date.end,
        consultant,
        structure_option: structureOption,
        franchise,
        creditType,
        rangeDate,
        payment_conciliator: paymentConciliator,
        financial_solutions: financialSolution,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as comissões. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.search = false;
        });

      this.getTotalizer({
        customer,
        partner_company: partnerCompany,
        product,
        date_start: range_date.start,
        date_end: range_date.end,
        consultant,
        structure_option: structureOption,
        franchise,
        creditType,
        rangeDate,
        payment_conciliator: paymentConciliator,
        financial_solutions: financialSolution,
      }).catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar os dados do totalizador. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

      this.searchChartCommissionManagement({
        customer,
        partner_company: partnerCompany,
        product,
        date_start: range_date.start,
        date_end: range_date.end,
        consultant,
        structure_option: structureOption,
        franchise,
        creditType,
        rangeDate,
        payment_conciliator: paymentConciliator,
        financial_solutions: financialSolution,
      }).catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar os dados do gráfico. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    clearSearch() {
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
